import Vue from 'vue'
import App from './App.vue'
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import { getDatabase } from "firebase/database"

const firebaseConfig = {
  apiKey: "AIzaSyDzTXc0IDRmeisC738ZnwwbSwrbh-vLwQQ",
  authDomain: "jims-teams.firebaseapp.com",
  projectId: "jims-teams",
  storageBucket: "jims-teams.appspot.com",
  messagingSenderId: "229091089136",
  appId: "1:229091089136:web:a2651320e8a5c183924035",
  measurementId: "G-ZGL0WZC3VC",
  databaseURL: "https://jims-teams-default-rtdb.firebaseio.com"
}

const app = initializeApp(firebaseConfig)
export const db = getDatabase(app)
getAnalytics(app)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
