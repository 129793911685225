<template>
  <div id="app">
    <flickity class="carousel" ref="flickity" :options="flickityOptions">
      <div v-for="(item,i) of items" :key="i" class="carousel-cell">
        <img :src="require(`@/assets/films/${item + 1}.jpg`)">
      </div>
    </flickity>
  </div>
</template>

<script>
import { db } from "@/main"
import { ref, set} from "firebase/database"
import Flickity from 'vue-flickity'
export default {
  name: 'App',
  async created() {
    this.current = 1
    await this.$nextTick()
    this.$refs.flickity.on('change', num => {
      this.current = num + 1
    })
  },
  components: {
    Flickity
  },
  data() {
    return {
      current: 0,
      items: [...Array(32).keys()],
      flickityOptions: {
        resize: true,
        imagesLoaded: true,
        prevNextButtons: true,
        pageDots: false,
        wrapAround: true,
      }
    }
  },
  watch: {
    current(val) {
      set(ref(db, '/'), val)
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
* {
  touch-action: manipulation;
}
body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  // margin-top: 60px;
}
.flickity-viewport {
  height: 100vh !important;
  // width: 100%;
}
// .carousel{
//   // @media (max-width: )
//   img {
//     width: 75%;
//   }
// }
.carousel-cell {
  width: 100%;
  img {
    width: initial;
    width: 90%;
    padding: 1rem 0;
  }
  @media (min-width: 525px) {
    img {
      width: 25rem;
    }
  }
}
</style>
